import React, { useEffect, useRef, useState } from "react";
import { Trans } from "gatsby-plugin-react-i18next";
import SolutionsContent, { SolutionsData } from "./solutionsContent";
import { Minus, Plus } from "react-feather";

const SolutionsNavbar = ({ selectedTab, onTabClick }: any) => {
  const [expandedTab, setExpandedTab] = useState<string | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (expandedTab) {
      // If a tab is expanded, scroll to the top of its content
      contentRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [expandedTab]);

  const handleTabClick = (item: string) => {
    if (expandedTab === item) {
      // If the clicked tab is already expanded, collapse it
      setExpandedTab(null);
    } else {
      // If a different tab is clicked, expand it
      setExpandedTab(item);
      onTabClick(item);
    }
  };

  return (
    <div className="md:max-w-[34%] min-w-[34%] sm:w-[60%] sm:max-w-full flex flex-col md:sticky md:top-[110px] md:max-h-[calc(100vh-110px)]">
      {Object.keys(SolutionsData).map((item, index) => {
        return (
          <div key={item} ref={expandedTab === item ? contentRef : null}
            className={
              SolutionsData[selectedTab] === SolutionsData[item]
                ? "space-y-8 bg-[#E6EEFF] cursor-pointer"
                : "space-y-8 bg-[#FAFBFC] cursor-pointer"
            }
            onClick={() => handleTabClick(item)}
          >
            <div
                className={
                    SolutionsData[selectedTab] === SolutionsData[item]
                    ? "text-blue-700 flex gap-6 px-5 py-5 justify-between"
                    : "text-[#505F79] flex gap-6 px-5 py-5 justify-between"
                }
            >
              <p className="text-lg sm:text-lg font-bold">0{index + 1}.</p>
              <p className="text-base sm:text-lg font-medium max-w-[70%] md:text-xl min-w-[50%]">
                <Trans i18nKey={`${SolutionsData[item].heading}`} />
              </p>
              {expandedTab === item ? (
                <Minus className="w-[20px] h-[20px] rounded-full bg-white text-right" />
              ) : (
                <Plus className="w-[20px] h-[20px] rounded-full bg-white " />
              )}
            </div>
            <div className="h-[1px] sm:block shrink-0 grow bg-gray-300" />
            {SolutionsData[selectedTab] === SolutionsData[item] && expandedTab === item && (
              <div className="flex flex-col gap-16 px-6 py-4">
                <SolutionsContent selectedTab={selectedTab} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SolutionsNavbar;
